#veesual_look_recommendation_root .carousel,
#veesual_look_recommendation_modal_root .carousel {
    &--container {
        width: 100%;
        flex: 1;
        display: flex !important;
        align-items: center;
        justify-content: center;
        overflow-y: auto;

        .slick-slider {
            width: 100%;
        }

        .slick-list,
        .slick-track {
            width: 100%;
        }

        .slick-track {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            padding-bottom: 24px;
        }

        .slick-slide {
            &:focus {
                box-shadow: none !important;
            }

            &>div {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .slick-slide {
            height: auto;
            margin: 8px;
        }

        .slick-dots {
            bottom: 0px;
            margin-bottom: 8px;
        }
    }

    &--look-detail--container {
        position: relative;
        display: flex !important;
        align-items: center;
        justify-content: center;
        max-width: unset;
        width: 100%;
        max-height: calc(100% - 50px);

        @include breakpoints(sm) {
            max-width: 50%;
            max-height: unset;
            justify-content: flex-start;
        }

        &:hover .card--look-option {
            opacity: 1;
        }

        .slick-slider {
            width: 100%;
            height: 100%;
        }

        .slick-list,
        .slick-track {
            width: 100%;
            height: 100%;
        }

        .slick-track {
            display: flex;
            align-items: flex-start;
            justify-content: center;
        }

        .slick-slide {
            height: 100%;

            &:focus {
                box-shadow: none !important;
            }

            &>div {
                height: 100%;
                width: 100%;
            }
        }

        .slick-dots {
            bottom: 0px;
            margin-bottom: 8px;
        }
    }

    &--container-no-swipe {
        .slick-track {
            gap: 16px;
            padding-bottom: 8px;
        }
    }

    &--arrow {

        &.slick-prev,
        &.slick-prev:hover,
        &.slick-next,
        &.slick-next:hover {
            cursor: pointer;
            position: absolute;
            display: flex !important;
            height: 2rem !important;
            width: 2rem !important;
            background: $background !important;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
            color: black;

            svg {
                font-size: 13px;
                color: var(--scrollbar-color);
            }
        }

        &:before {
            display: none;
        }
    }

    &--look-detail--arrow {

        &.slick-prev,
        &.slick-prev:hover,
        &.slick-next,
        &.slick-next:hover {
            cursor: pointer;
            position: absolute;
            display: flex !important;
            height: 2rem !important;
            width: 2rem !important;
            align-items: center;
            justify-content: center;
            color: black;

            svg {
                font-size: 20px;
                color: var(--scrollbar-color);
            }
        }

        &:before {
            display: none;
        }
    }

    &--left {
        left: 4px !important;
        z-index: 999;

        @include breakpoints(sm) {
            left: 5px !important;
        }
    }

    &--right {
        right: 4px !important;
        z-index: 999;

        @include breakpoints(sm) {
            right: 5px !important;
        }
    }

}