/**
 * Responsive breakpoints
 */

$breakpoints: (
  xxsm: em(480),
  xsm: em(600),
  sm: em(768),
  smd: em(992),
  md: em(1024),
  mdl: em(1200),
  lg: em(1260),
  xlg: em(1480),
  xxlg: em(1680),
  xxxlg: em(1920),
);

@mixin breakpoints($breakpoint) {
  @media screen and (min-width: map-get($map: $breakpoints, $key: $breakpoint)) {
    @content;
  }
}

@mixin breakpoints_px($px) {
  @media screen and (min-width: $px) {
    @content;
  }
}

$mx_breakpoints: (
  xxsm: em(479),
  xsm: em(599),
  sm: em(767),
  smd: em(991),
  md: em(1023),
  mdl: em(1199),
  lg: em(1259),
  xlg: em(1479),
  xxlg: em(1679),
  xxxlg: em(1919),
);


@mixin mx_breakpoints($breakpoint) {
  @media screen and (max-width: map-get($map: $mx_breakpoints, $key: $breakpoint)) {
    @content;
  }
}

@mixin mx_breakpoints_px($px) {
  @media screen and (max-width: $px) {
    @content;
  }
}