#veesual_look_recommendation_root .card,
#veesual_look_recommendation_modal_root .card {
    $el: &;

    &--container {
        width: 100%;
        /* la largeur est definie par le parent */
        border: none;
        border-radius: 0;
        overflow: hidden;
        padding: 16px;

        .ant-card-body {
            padding: 0;
            padding-left: 16px;
        }

        .ant-card-cover {
            margin-top: 0 !important;
            margin-right: 0 !important;
            margin-left: 0 !important;
            border-radius: 0;
        }

        &.ant-card .ant-card-cover >* {
            display: flex;
        }

        &-favorite {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            box-shadow: 0 0 1px rgba(40,41,61,.04), 0 2px 4px rgba(96,97,112,.16);

            .ant-card-body {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                flex: 5;
            }

            .ant-card-cover {
                flex: 1;
            }
        }
    }

    &--background {
        position: relative;
        width: 100%;
        /* la largeur est definie par le parent */
        height: 0;
        padding-bottom: 125%;
        /* calcul de la hauteur en fonction de la largeur du parent */
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 0;
        background-color: transparent;
    }

    &--image {
        width: 100%;
    }

    &--image:hover .card--look-option {
        opacity: 1;
    }

    &--image-container {
        position: relative;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        display: flex;
    }

    &--image-blur {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        flex-direction: column;
    }

    &--blur {
        filter: blur(3px);
        -webkit-filter: blur(3px);
    }

    &--title {
        margin-top: 0 !important;
        margin-bottom: 10px;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &--text {
        line-height: 16px;
        margin-bottom: 12px;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &--without-description {
        .card--text {
            margin-bottom: 8px;
        }

        .card--title {
            margin-bottom: 12px;
        }
    }

    &--price--promotion {
        font-size: 12px !important;
        margin-top: 0 !important;
    }

    &--price--original {
        text-decoration: line-through;
    }

    &--price--percent {
        margin-left: 8px;
    }

    &--favorite {
        position: absolute;
        top: 0;
        right: 0;

        svg {
            height: 16px;
            width: 16px;
        }
    }

    &--actions {
        width: 100%;
        padding: 0 8px;

        &>button,
        &>.select--container {
            margin-bottom: 8px;
        }

        &>button:last-child {
            margin-bottom: 0;
        }

        .action--see-product.action--changing-size {
            display: none;
        }

        @include breakpoints(sm) {
            .action--see-product.action--changing-size {
                display: block;
            }

            .action--see-look {
                display: none;
            }
        }
    }

    // Button
    &--light {
        border-radius: 8px;
        width: 100%;
        background-color: $background !important;
        color: #1c1c28;
        border: 1px solid var(--scrollbar-color) !important;

        &>span {
            display: table;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $darkcolor;
        }
    }

    &--dark {
        border-radius: 8px;
        background-color: #000000 !important;
        width: 100%;
        border: none;

        &>span {
            display: table;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: $lightcolor;
        }
    }

    &--favorites-options {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: 140px;

        &>div {
            margin-bottom: 8px;
        }
    }

    &-cart--add {
        display: inline-block;
        margin-top: 10px;

        @include breakpoints(mdl) {
            display: none;
        }
    }

    &--look-option {
        position: absolute;
        opacity: 1;

        @include breakpoints(sm) {
            opacity: 0;
            transition: opacity 0.3s;
        }

        bottom: 0;
        width: 100%;
        height: 32px;
    }

    &--look-option-container {
        display: flex;
        justify-content: flex-end;
        padding: 0px 8px;
        height: 100%;
    }

    &--text-container {
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 8px;
    }
}