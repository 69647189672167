#veesual_look_recommendation_root .look-detail,
#veesual_look_recommendation_modal_root .look-detail {
    &--container {
        display: flex;
        height: calc(100vh - 32px);
        max-width: calc(100vw - 32px);
        background-color: white;
        overflow-y: auto;
        flex-direction: column;
        align-items: center;

        @include breakpoints(sm) {
            flex-direction: row;
            flex: 1;
            overflow-y: unset;
            align-items: unset;
        }
    }

    &--look-image-root {
        display: flex;
        max-width: unset;
        width: auto;
        justify-content: center;
        height: 100%;

        @include breakpoints(sm) {
            justify-content: flex-start;
        }
    }

    &--look-image {
        object-fit: cover;
        height: 100%;
        width: auto !important;

        @include breakpoints(sm) {
            width: 100%;
        }
    }

    &--detail-container {
        flex: 1;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        padding: 16px 16px;
        gap: 8px;

        @include breakpoints(sm) {
            overflow-y: auto;
            height: 100%;
            display: block;
            padding: 32px 40px;
        }

        >div {
            margin-bottom: 16px;
        }

        :last-child {
            margin-bottom: 0px;
        }
    }
}