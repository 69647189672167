#veesual_look_recommendation_root .modal,
#veesual_look_recommendation_modal_root .modal {

    top: 0;
    width: auto !important;
    max-width: unset;
    height: auto !important;
    position: relative !important;

    @include breakpoints(sm) {
        width: auto !important;
        top: 20;
    }

    .ant-modal,
    .ant-modal-content {
        margin: 0;
        padding: 0;
        border-radius: 0;
        top: 0;
    }

    .ant-modal-body {
        display: flex;
        padding: 0px;
        max-height: 100vh;
    }

    .ant-modal-title {
        text-align: center;
    }
}