#veesual_look_recommendation_root .button,
#veesual_look_recommendation_modal_root .button {
    &--outlined {
        border: 1px solid;
        width: 100%;
    }

    &--icon {
        border: none;
        background-color: transparent;
        height: 100%;
    }

    &--underlined {
        display: inline-block;
        cursor: pointer;
        padding: 0 10px 0 10px;
        color: var(--primary-color);
        width: auto;

        >span {
            text-overflow: ellipsis;
            white-space: nowrap;
            text-decoration: underline;
        }
    }
}